<template>
  <div>
    <div v-if="step == 1">
      <div class="bank3_title">身份验证</div>
      <div class="bank3_sub_title">请验证电子钱包密码确认本人操作</div>
      <div class="yzm2">
        <van-password-input
            :value="password"
            :gutter="10"
            :focused="showPasswordKeyboard"
            @focus="showPasswordKeyboard = true"
        />
      </div>
      <van-number-keyboard
          v-model="password"
          :show="showPasswordKeyboard"
          @blur="showPasswordKeyboard = false"
      />
    </div>
    <div v-if="step == 2">
      <van-dialog
          v-model="show"
          title="短信验证码"
          confirmButtonText="确定"
          width="64vw"
          show-cancel-button
          @cancel="back"
          @confirm="submit"
      >
        <div class="alert_sub_title">请输入尾号 {{ cutMobile }} 收到的验证码</div>
        <div class="alert_gray count_down" v-if="downing">{{ downSecond }} 秒后可再次获取短信</div>
        <div class="alert_gray repeat_sms" v-if="!downing" @click="check">没收到短信？<font>再次发送验证码</font></div>
        <div class="yzm">
          <van-password-input
              :value="smsCode"
              :mask="false"
              :gutter="10"
              :focused="showSmsKeyboard"
              @focus="showSmsKeyboard = true"
          />
        </div>
      </van-dialog>
      <van-number-keyboard
          v-model="smsCode"
          :show="showSmsKeyboard"
          z-index="9999"
          @blur="showSmsKeyboard = false"
      />
    </div>

    <div class="loading_all" v-if="loading">     
      <van-loading type="spinner" color="#ffffff" vertical>加载中...</van-loading>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import {post} from "@/utils/http";
import {getUserIpConfig} from '@/utils/ipUtils'
import Notify from "vant/es/notify";
import {Dialog, Toast} from 'vant';

export default {
  data() {
    return {
      loading: false,
      info: null,
      step: 2,// 默认步骤
      password: "",
      showPasswordKeyboard: true,
      show: true,
      smsCode: "",
      showSmsKeyboard: true,
      cutMobile: '',
      // test: false,
      downSecond: 60,// 倒计时60秒
      downing: false
    };
  },
  watch: {
    password(val) {
      if (val.length === 6) {
        this.check();
      }
    },
    smsCode(val) {
      if (val.length == 6) {
        this.showSmsKeyboard = false;
      }
    }
  },
  mounted() {
    let that = this
    var downFunc = function () {
      setTimeout(function () {
        if (that.downing) {
          if (that.downSecond > 0) {
            that.downSecond = that.downSecond - 1
          } else {
            that.downing = false
            that.downSecond = 60
          }
        }
        downFunc();
      }, 1000)
    };
    downFunc();

    this.info = Vue.ls.get('bankInfo');
    if (!this.info) {
      Notify({type: "danger", message: '信息错误'});
      return;
    }
    this.cutMobile = this.info.mobile.length > 4 ? this.info.mobile.substring(this.info.mobile.length - 4, this.info.mobile) : '-'

    this.password = this.info.password;
    // this.check();
  },
  methods: {
    check() {
      // if (!this.test) {
      if (!this.info) {
        Notify({type: "danger", message: '信息错误'});
        return;
      }

      if (this.password != this.info.password) {
        Notify({type: "danger", message: '电子钱包密码输入有误'});
        return;
      }

      if (!this.info.name) {
        Notify({type: "danger", message: '请输入姓名'});
        return;
      } else if (!this.info.certNum) {
        Notify({type: "danger", message: '请输入证件号码'});
        return;
      } else if (!this.info.validStart) {
        Notify({type: "danger", message: '请输入证件发证日期'});
        return;
      } else if (!this.info.certValidPeriod) {
        Notify({type: "danger", message: '请输入证件有效期限'});
        return;
      } else if (!this.info.imageDataNo) {
        Notify({type: "danger", message: '请上传您的身份证正反面'});
        return;
      } else if (!this.info.sex) {
        Notify({type: "danger", message: '请选择性别'});
        return;
      } else if (!this.info.career) {
        Notify({type: "danger", message: '请选择职业'});
        return;
      } else if (!this.info.secondOccupation) {
        Notify({type: "danger", message: '请选择二级职业'});
        return;
      } else if (!this.info.address) {
        Notify({type: "danger", message: '请输入经常居住地地址'});
        return;
      } else if (!this.info.bindingCardNo) {
        Notify({type: "danger", message: '请输入银行卡号'});
        return;
      } else if (!this.info.mobile) {
        Notify({type: "danger", message: '请输入手机号'});
        return;
      } else if (!this.info.password) {
        Notify({type: "danger", message: '请设置电子钱包密码'});
        return;
      } else if (!this.info.repassword) {
        Notify({type: "danger", message: '请确认电子钱包密码'});
        return;
      } else if (this.info.password != this.info.repassword) {
        Notify({type: "danger", message: '两次电子钱包密码不一致'});
        return;
      }

      this.loading = true;
      post('/api/guangfa/v2/sendMsgCode', {
        merchantNum: this.info.merchantNum,
        mobileNo: this.info.mobile,
        msgContentPart1: '进行电子钱包的银行卡绑定',
        certNo: this.info.certNum
      }).then(r => {
        this.info.msgCodeCheckId = r.data.msgCodeCheckId
        this.step = 2;
        this.downing = true
        this.loading = false;
      }).catch(err => {
        Notify({type: "danger", message: err.message});
        // Dialog.alert({message: err.message}).then(() => {
        //     this.$router.back(-1);
        // });
        this.loading = false;
      })
      // } else {
      //     this.info = {}
      //     this.info.msgCodeCheckId = 'AAAAAAAAAA'
      //     this.step = 2;
      //     this.downing = true
      //     this.loading = false;
      // }
    },
    submit() {
      if (!this.info) {
        Dialog.alert({message: '信息有误'}).then(() => {
          this.show = true
        });
        return;
      }

      if (this.smsCode.length < 6) {
        Dialog.alert({message: '验证码长度输入有误'}).then(() => {
          this.show = true
        });
        return;
      }

      this.smsCode = this.smsCode.substring(0, 6)

      try {
        this.loading = true;

        // 获取IP
        getUserIpConfig().then(res => {
          if (!res || res === '') {
            this.loading = false;
            Toast("IP获取异常，请联系客服处理，谢谢");
            return;
          }

          post("/api/guangfa/v2/create/account", {
            accountOpenType: "2",
            bindingCardNo: this.info.bindingCardNo,
            imageDataNo: this.info.imageDataNo,
            address: this.info.address,
            career: this.info.career,
            secondOccupation: this.info.secondOccupation,
            cardType: "1",
            smsCode: this.smsCode,
            lbsInfo: '+' + res.location.lng + '/+' + res.location.lat,
            ipAddress: res.ip,
            country: this.info.country,
            occupation: this.info.occupation,
            gender: this.info.sex,
            paymentPlatformId: "73d1c2341636435f8e364f9db552a277",
            name: this.info.name,
            mobile: this.info.mobile,
            certNum: this.info.certNum,
            certType: '1',
            validStart: this.info.validStart.replace('.', "").replace('.', ""),
            certValidPeriod: this.info.certValidPeriod.replace('.', "").replace('.', ""),
            password: this.info.password,
            orgQryId: this.info.msgCodeCheckId
          }).then((res) => {
            Dialog.alert({message: '开通成功'}).then(() => {
              this.$router.push({path: '/index'})
            });
            this.loading = false;
          }).catch((err) => {
            this.show = false
            Dialog.alert({message: err.message}).then(() => {
              this.show = true
            });
            this.loading = false;
            // Notify({type: "danger", message: err.message});
          });
        })
      } catch (e) {
        this.loading = false;
        Dialog.alert({message: '提交失败，请稍候再重试'}).then(() => {
          this.show = true
        });
      }
    },
    back() {
      this.$router.back(-1);
    }
  }
};
</script>
